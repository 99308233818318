import { Flex, Text, Box, Separator, Circle, Icon, Token } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import React, { useMemo } from 'react'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { StepSection } from './components/StepSection'
import { HeaderSummarySkeleton } from './components/HeaderSummarySkeleton'
import { GradeSection } from './components/GradeSection'

interface Props {
  grade: FinalGrade
  isLoading: boolean
  isEligible?: boolean
  periodCategory: ReviewCycleCategory | undefined
  periodName: string | undefined
  title: string
  firstStepTitle: string
  firstStepSubTitle: string
  firstStepCount: number
  firstStepRequiredCount: number
  middleStepRequiredCount: number
  middleStepCount: number
  middleStepSubTitle: string
  middleStepTitle: string
  lastStepTitle: string
  lastStepSubtitle: string
}

export const PerformanceSummaryHeader = ({
  isLoading,
  isEligible = false,
  grade,
  periodCategory,
  periodName,
  title,
  firstStepTitle,
  firstStepSubTitle,
  firstStepCount,
  firstStepRequiredCount,
  middleStepRequiredCount,
  middleStepCount,
  middleStepTitle,
  middleStepSubTitle,
  lastStepTitle,
  lastStepSubtitle,
}: Props) => {
  const pickStepProgressColor = (data: number, amountRequired: number) => {
    return data === amountRequired ? Token.color.success : Token.color.accent
  }

  const pickStepProgressText = (data: number, amountRequired: number) => {
    return `${data}/${amountRequired}`
  }

  const subTitle = useMemo(() => {
    return periodCategory &&
      [
        ReviewCycleCategory.PIP,
        ReviewCycleCategory.PIP_V2,
        ReviewCycleCategory.Probation,
      ].includes(periodCategory)
      ? 'Assessed grade'
      : 'Overall grade'
  }, [periodCategory])

  return isLoading ? (
    <HeaderSummarySkeleton />
  ) : (
    <Flex pr="s-72" gap="s-72" style={{ flexGrow: 1 }} justifyContent="space-between">
      <GradeSection
        grade={grade}
        periodCategory={periodCategory}
        periodName={periodName}
        subTitle={subTitle}
      />
      <Flex gap="s-16" flexDirection="column">
        <Text color={Token.color.greyTone50} variant="emphasis2">
          {title}
        </Text>

        <StepSection
          title={firstStepTitle}
          subTitle={firstStepSubTitle}
          color={pickStepProgressColor(firstStepCount, firstStepRequiredCount)}
          value={
            firstStepRequiredCount === 0 ? 0 : firstStepCount / firstStepRequiredCount
          }
          progressText={pickStepProgressText(firstStepCount, firstStepRequiredCount)}
        />
      </Flex>

      <StepSection
        alignItems="flex-end"
        title={middleStepTitle}
        subTitle={middleStepSubTitle}
        color={pickStepProgressColor(middleStepCount, middleStepRequiredCount)}
        value={middleStepCount}
        progressText={pickStepProgressText(middleStepCount, middleStepRequiredCount)}
      />

      <Flex gap="s-16" justifyContent="space-between" alignItems="flex-end">
        <Box mb="-s-8" height={60}>
          <Separator orientation="vertical" />
        </Box>
        <Circle bg={Token.color.greyTone10} size={44}>
          <Icon name={isEligible ? 'Trophy' : 'Profile'} size={24} />
        </Circle>

        <Flex gap="s-2" flexDirection="column">
          <Text whiteSpace="nowrap" variant="emphasis1">
            {lastStepTitle}
          </Text>
          <Text
            color={isEligible ? Token.color.success : Token.color.greyTone50}
            whiteSpace="nowrap"
            variant="body2"
          >
            {lastStepSubtitle}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
