import React, { useEffect, useState } from 'react'
import { ChatProps } from '@components/Chat/common'
import { Action, Box, Subheader } from '@revolut/ui-kit'
import ChatMessagesList from '@components/Chat/ChatMessagesList'
import ChatSidebar from '@components/Chat/ChatSidebar'
import { CommonCommentsSectionProps } from '@src/features/Comments/CommentsSection'
import ChatTextEditor, {
  ChatTextEditorVariant,
} from '@components/Chat/ChatTextEditor/ChatTextEditor'
import ChatTagsManager from '@components/Chat/ChatTagsManager'

interface Props extends ChatProps, CommonCommentsSectionProps {}

const Chat = (props: Props) => {
  const {
    data,
    refetch,
    loadNextPage,
    onAddMessage,
    onSideOpen,
    onSideClose,
    setSidebarOpen,
    title = 'Comments',
    maxShown = 3,
    canAddComments = true,
    withFileUploader,
    withInfiniteLoading,
  } = props
  const [isSideOpen, setSideOpen] = useState<boolean>(false)

  useEffect(() => {
    if (setSidebarOpen) {
      setSidebarOpen.current = setSideOpen
    }
  }, [])

  return (
    <Box>
      <ChatTagsManager>
        <Box pb="s-16">
          <Subheader variant="nested">
            <Subheader.Title>{title}</Subheader.Title>
            {!props.withInfiniteLoading && data?.length ? (
              <Subheader.Side>
                <Action
                  onClick={() => {
                    onSideOpen?.()
                    setSideOpen(true)
                  }}
                >
                  See all ({data.length})
                </Action>
              </Subheader.Side>
            ) : null}
          </Subheader>
          {canAddComments ? (
            <ChatTextEditor
              withFileUploader={withFileUploader}
              variant={ChatTextEditorVariant.Input}
              onSubmit={(text, createTask, attachedFiles) => {
                return onAddMessage(text, createTask, attachedFiles).then(refetch)
              }}
            />
          ) : null}
        </Box>
        <ChatMessagesList
          {...props}
          withInfiniteLoading={withInfiniteLoading}
          loadNextPage={loadNextPage}
          maxShown={maxShown}
          showNoDataMessage
        />
        {!withInfiniteLoading && (
          <ChatSidebar
            {...props}
            isOpen={isSideOpen}
            onClose={() => {
              onSideClose?.()
              setSideOpen(false)
            }}
            withFileUploader={withFileUploader}
          />
        )}
      </ChatTagsManager>
    </Box>
  )
}

export default Chat
